import React, { useState, useRef, useEffect } from 'react';

import ReactPlayer from 'react-player';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';



import CCTVPiscineBuissonRondVideo from '../assets/video/buissonrond.gif'
import CCTVChateau from '../assets/video/chateau.gif'
import CCTVRondPoint from '../assets/video/rondpoint.gif'
import CCTVMairie from '../assets/video/mairie.mp4'
import Logo from '../assets/img/tools/cctv/logo.png'
import ButtonImage from '../assets/img/tools/cctv/button.png'
import style from './CCTV.module.css'

import notifSound from '../assets/sounds/message-notification.mp3';


import { deleteCookie, getCookie, setCookie } from "../urbanlock"




export const CCTVBottomLeftAnnotation = () => {
    return (
        <span style={{ opacity: "0.4", fontFamily: "'Jura', sans-serif", position: 'absolute', bottom: "20px", left: "20px", zIndex: 1, color: "#fff", backgroundColor: "#000", padding: "1px 5px 1px 5px" }}><Now /> <br /><DateOfToday /></span>
    )
}

export const CCTVTopRightAnnotation = ({ camNumber, sector }: { camNumber: string; sector: string }) => {
    return (
        <span style={{ opacity: "0.4", fontFamily: "'Jura', sans-serif", position: 'absolute', top: "20px", right: "20px", zIndex: 1, color: "#fff", backgroundColor: "#000", padding: "1px 5px 1px 5px" }}>
            CAM {camNumber} <br />
            SECTEUR {sector}
        </span>
    )
}



export const DateOfToday: React.FC = () => {
    const [today, setToday] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setToday(new Date()), 1000);
        return () => {
            clearInterval(timer);
        };
    }, []); // Empty dependency array ensures the effect runs only once on mount

    const formattedDate = today.toLocaleDateString('fr-FR'); // DD/MM/YYYY format

    return <span>{formattedDate}</span>;
};

export const Now: React.FC = () => {
    const [currentTime, setCurrentTime] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(new Date()), 1000);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return <span>{formattedTime}</span>;
};



export const CCTVVideo = ({ sourceVideo, camNumber, sector }: { sourceVideo: string, camNumber: string, sector: string }) => {
    return (
        <div style={{ position: "relative" }}>
            <img alt="videosurveillance" src={sourceVideo} width={"100%"} height={"30%"} style={{
                marginBottom: "10px",
                position: "relative",
                zIndex: 0
            }} />
            <CCTVBottomLeftAnnotation />
            <CCTVTopRightAnnotation camNumber={camNumber} sector={sector} />
        </div>
    )
}


export default function CCTV(params: any) {

    const gameCode = getCookie("game-code")

    if (getCookie('new_tool')) {
        deleteCookie('new_tool')
    }



    const [unlocked, setUnlocked] = useState(getCookie("cctv_has_been_unlocked") || false);


    const LockedScreen = () => {
        const loginInputRef = useRef<HTMLInputElement | null>(null);
        const [openPasswordReset, setOpenPasswordReset] = React.useState(false);

        const handleButtonPasswordResetClick = () => {
            setOpenPasswordReset(true);
        }
        const handleButtonPasswordResetClose = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setOpenPasswordReset(false);
        };



        const Login = () => {
            let password = loginInputRef.current?.value

            if (password?.toLowerCase() === "hyvert") {
                setCookie("cctv_has_been_unlocked", true, 1)
                setUnlocked(true)
                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=cctv`).then(data => {
                    if (data.status !== 404) {
                        // adding new hint
                        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEWHINT}?game=${gameCode}&hint=camera`).then(data => {
                        })
                    }
                })

            } else {
                if (loginInputRef.current !== null) {
                    loginInputRef.current.value = '';
                    loginInputRef.current.classList.add(style.redBorder); // Add the class for red border
                }
                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_ADD_MALUS}?game=${gameCode}&malus=50`).then(data => { })
            }
        }

        return (
            <main className={style.container}>
                <img alt="logo" className={style.logo} src={Logo} /><br />
                <span className={style.title}>Saisir votre identifiant de connexion :</span><br /><br />
                <input className={style.passwordInput} type='password' maxLength={6} required ref={loginInputRef} /><br />
                <button onClick={Login} className={style.submit}>CONNEXION</button>
                <p style={{ color: '#fff' }} onClick={handleButtonPasswordResetClick}>Mot de passe oublié ?</p>
                <Dialog
                    open={openPasswordReset}
                    onClose={handleButtonPasswordResetClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Indice de mot de passe oublié
                    </DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText>
                            Quelle est la saison préféré de J&S ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions id="buttonBox">
                        <Button id="confirm" onClick={handleButtonPasswordResetClose}>Fermer</Button>
                    </DialogActions>
                </Dialog>
            </main>
        )
    }

    const UnLockedScreen = () => {
        const [open, setOpen] = React.useState(false);
        const [message, setMessage] = useState('');
        const [finalVideo, setFinalVideo] = React.useState(false)
        const [newMessageSnackOpen, setNewMessageSnackOpen] = React.useState(false);
        const [cameraIdError, setCameraIdError] = React.useState(false)
        const [errorMessage, setErrorMessage] = React.useState("")

        const handleChange = (event: any) => {
            setMessage(event.target.value.toUpperCase());
        };

        const handleButtonClick = () => {
            setOpen(true);
        }
        const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
            event.stopPropagation();
            setOpen(false);
        };
        const handleSubmit = () => {

            if (message.toLowerCase() === "br037" || message.toLowerCase() === "ag015" || message.toLowerCase() === "ch061") {
                setCameraIdError(true)
                setErrorMessage("Cette caméra est déjà affiché à l'écran.")
                setFinalVideo(false)

            } else if (message.toLowerCase() === "ci048") {
                setFinalVideo(true)
                const gameCode = getCookie("game-code")

                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_PUSH_NEW_MESSAGE_IN_CONVO}?game=${gameCode}&message=maitre-riboule-asking-for-news`).then(data => {
                    if (data.status === 200) {
                        setNewMessageSnackOpen(true)
                        const audio = new Audio(notifSound);
                        audio.loop = false;
                        audio.play();
                        setCookie("new_message", "maitre-riboule", 1)
                    }
                })


            } else {
                setCameraIdError(true)
                setErrorMessage("Identifiant de caméra inconnu.")
                setFinalVideo(false)
                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_ADD_MALUS}?game=${gameCode}&malus=50`).then(data => { })

            }

            // fetch(`https://unlock-hint-hxi3o7okfq-uc.a.run.app?game=${gameCode}&hint=${hintId}&step=${currentStep}`).then(response => response.json()).then(data => {
            // setOpen(false);
            // })
        }

        const playerRef = useRef<ReactPlayer>(null);

        if (finalVideo) {


            const handleFullscreen = () => {
                const player = playerRef.current;
                if (player) {
                    const playerContainer = player.getInternalPlayer();
                    if (playerContainer.requestFullscreen) {
                        playerContainer.requestFullscreen();
                    } else if (playerContainer.mozRequestFullScreen) {
                        playerContainer.mozRequestFullScreen();
                    } else if (playerContainer.webkitRequestFullscreen) {
                        playerContainer.webkitRequestFullscreen();
                    } else if (playerContainer.msRequestFullscreen) {
                        playerContainer.msRequestFullscreen();
                    }
                }
            };

            return (

                <div style={{ display: "flex", height: "100%", backgroundColor: "#000" }}>
                    <ReactPlayer
                        ref={playerRef}
                        playing={true}
                        controls={true}
                        url={[CCTVMairie]}
                        muted={false}
                        width={"100%"}
                        height={"30%"}
                        loop={false}
                        volume={1}
                        style={{
                            margin: "auto"
                        }}
                    />

                    <button onClick={handleFullscreen} style={{ position: 'absolute', bottom: '100px', left: 0, right: 0, marginLeft: 'auto', marginRight: 'auto', height: '50px', width: '80%', borderRadius: '5px', backgroundColor: '#fff', fontFamily: 'Jura' }}>PLEIN ÉCRAN</button>

                    <Snackbar open={newMessageSnackOpen} autoHideDuration={5000} onClose={() => setNewMessageSnackOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                        <Alert severity="info" sx={{ width: '100%' }}>
                            Vous avez reçu un nouveau message de Maître Riboule
                        </Alert>
                    </Snackbar>
                </div>

            )

        } else {

            return (
                <>

                    <CCTVVideo sourceVideo={CCTVPiscineBuissonRondVideo} camNumber='BR037' sector='BR' />
                    <CCTVVideo sourceVideo={CCTVChateau} camNumber='CH061' sector='CH' />
                    <CCTVVideo sourceVideo={CCTVRondPoint} camNumber='AG015' sector='AG' />

                    <img alt="searchButton" src={ButtonImage} style={{
                        // height: 50,
                        width: 100,
                        position: "fixed",
                        bottom: 20,
                        right: 20,
                        borderRadius: 5,
                        border: '3px solid back'
                    }}
                        onClick={handleButtonClick}
                    />

                    <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            Identifiant de caméra
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                De quelle caméra souhaitez vous consulter l'enregistrement:
                            </DialogContentText>
                            <TextField
                                style={{ textTransform: "uppercase" }}
                                autoFocus
                                margin="dense"
                                id="camera_id"
                                label="identifiant camera"
                                type="text"
                                fullWidth
                                value={message}
                                variant="outlined"
                                onChange={handleChange}
                                placeholder='XX000'
                                error={cameraIdError ? true : false}
                                helperText={cameraIdError ? errorMessage : ""}
                            />
                        </DialogContent>
                        <DialogActions id="buttonBox">
                            <Button id="confirm" onClick={handleSubmit}>Confirmer</Button>
                            <Button id="cancel" onClick={handleClose}>
                                Annuler
                            </Button>
                        </DialogActions>
                    </Dialog>
                </>
            );
        }

    }

    return (
        unlocked ? <UnLockedScreen /> : <LockedScreen />
    );
}
