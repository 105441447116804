import { useNavigate, Outlet, useParams } from "react-router-dom";
import './ToolsDetails.css'
import Phone from '../components/Phone'
import CCTV from '../components/CCTV'
import LeftArrow from '../assets/img/leftArrow.svg'




const Layout = () => {
    const params = useParams();
    let navigate = useNavigate();

    const Tool = () => {
        if (params.outils === "Smartphone") {
            return <Phone params={params.outils} />
        }
        if (params.outils === "Vidéosurveillance") {
            return <CCTV />
        }
        return (<></>)
    }


    return (
        <>
            <div id="header">
                <img alt="" onClick={() => navigate(-1)} id="leftArrow" src={LeftArrow} />
                <p id="title">{params.outils}</p>

            </div>
            {/* <Phone params={params.outils} /> */}
            <Tool />
            <Outlet />
        </>
    )
};

export default Layout;