import React from "react";
import style from "./Pinpad.module.css"

import { getCookie, setCookie } from "../urbanlock"

const gameCode = getCookie("game-code")

const PASSCODE = "6523";

const Button = (props: any) => {
    return (
        <button className={style.pinpadButton} onClick={() => props.onButtonClick(props.value)}>
            {props.value}
        </button>
    );
};

const Input = (props: any) => {
    let className = "";

    if (props.isWrong) {
        className = style.error; // Use the generated class name from CSS module
    } else if (props.isUnlocked) {
        className = style.success; // Use the generated class name from CSS module
    }

    return (
        <div id="input-container">
            <input
                disabled
                type="password"
                className={className} // Apply the generated class name
                value={props.value}
            />
        </div>
    );
};

export default function Pinpad({ setUnlockedMessages }: any) {
    const [inputValue, setInputValue] = React.useState("");
    const [isUnlocked, setUnlocked] = React.useState(false);
    const [isWrong, setWrong] = React.useState(false);

    const handleButtonClick = (value: any) => {
        let newInputValue = inputValue + `${value}`;
        setInputValue(newInputValue);

        if (newInputValue.length > 4) {
            setUnlocked(false);
            setWrong(false);
            setInputValue(`${value}`);
        } else if (newInputValue.length === 4) {
            if (newInputValue === PASSCODE) {
                setUnlockedMessages(true)
                setCookie("private_messages_unlocked", true, 1)

                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=origami`).then(data => {
                    if (data.status !== 404) {
                        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=boule-de-papier`).then(data => {
                            if (data.status !== 404) {
                                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=carte-postale`).then(data => {
                                    if (data.status !== 404) {
                                        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=photo-theatre`).then(data => {
                                        })
                                    }
                                })
                            }
                        })
                    }
                })

            }
            else {
                setWrong(true)
                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_ADD_MALUS}?game=${gameCode}&malus=50`).then(data => { })
            }
        }
    };

    return (
        <>
            <h1 style={{ color: "#FFF", textAlign: "center", marginTop: 0 }}>Messages privés</h1>
            <div className={style.main}>
                <section className={style.numbers}>
                    <Input value={inputValue} isUnlocked={isUnlocked} isWrong={isWrong} />
                    <section className={style.row}>
                        <Button value={3} onButtonClick={handleButtonClick} />
                        <Button value={2} onButtonClick={handleButtonClick} />
                        <Button value={1} onButtonClick={handleButtonClick} />
                    </section>
                    <section className={style.row}>
                        <Button value={6} onButtonClick={handleButtonClick} />
                        <Button value={5} onButtonClick={handleButtonClick} />
                        <Button value={4} onButtonClick={handleButtonClick} />
                    </section>
                    <section className={style.row}>
                        <Button value={9} onButtonClick={handleButtonClick} />
                        <Button value={8} onButtonClick={handleButtonClick} />
                        <Button value={7} onButtonClick={handleButtonClick} />
                    </section>
                    <section className={style.row}>
                        <Button value={0} onButtonClick={handleButtonClick} />
                    </section>
                </section>
            </div>
        </>
    );
}
