import './Loading.scss'
import { useState, useEffect } from "react";
import Reverted from '../assets/img/reverted.png'
import Loader from '../assets/img/loader.gif'





function Loading() {
    const loadingGif = Loader
    const staticImage = Reverted

    const [heroImage, setHeroImage] = useState(staticImage)
    useEffect(() => {
      const intervalId = setInterval(() => {
          if (heroImage === staticImage) {
              setHeroImage(loadingGif);
          } else {
              setHeroImage(staticImage);
          }
      }, 3000);
  
      return () => {
          clearInterval(intervalId);
      };
  }, [heroImage, loadingGif, staticImage]);

    
    return (
        <div className='main'>
            <img className='loading-img' src={heroImage} alt="loadingImage" ></img>
            <div className="loading loading03">
                <span>C</span>
                <span>h</span>
                <span>a</span>
                <span>r</span>
                <span>g</span>
                <span>e</span>
                <span>m</span>
                <span>e</span>
                <span>n</span>
                <span>t</span>
                <span>&nbsp;</span>
                <span>d</span>
                <span>e</span>
                <span>&nbsp;</span>
                <span>l</span>
                <span>a</span>
                <span>&nbsp;</span>
                <span>p</span>
                <span>a</span>
                <span>r</span>
                <span>t</span>
                <span>i</span>
                <span>e</span>
                <span>.</span>
                <span>.</span>
                <span>.</span>
            </div>
        </div>
    );
}

export default Loading;