import { Outlet } from "react-router-dom";
import SimpleBottomNavigation from "../components/BottomNavigation";

const Layout = () => {
    return (
        <>            
            <SimpleBottomNavigation></SimpleBottomNavigation>
            <Outlet />
        </>
    )
};

export default Layout;