import { useNavigate } from "react-router-dom";
import style from './EndGame.module.css'




function EndGame() {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate('/score')
  }

  return (
    <div className={style.container}>
      <div className={style.message}>
        <p>
          Félicitations brillants détectives ! Votre sagacité a triomphé dans cette affaire. <br /><br />En mettant au grand jour la relation secrète entre Bastien et Erika, vous avez mis fin au mystère. <br /><br />Votre découverte de messages compromettants a non seulement blanchi Bastien en dévoilant la véritable coupable, mais a aussi confronté Maitre Riboule à un dilemme déchirant entre son devoir professionnel et sa vie personnelle.<br /><br /> Grâce à vos preuves, la justice a prévalu, innocençant Bastien et inculpant Erika, rétablissant ainsi l'équité dans cette histoire de mensonges et de trahisons. <br /><br /> Bravo pour avoir démêlé ces fils complexes et restauré la vérité dans cette quête passionnante de justice. </p>
        <button onClick={() => handleClick()}
        >Voir mon score</button>
      </div>
    </div>
  );
}

export default EndGame;