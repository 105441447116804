import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import "./AppBar.css"
import Box from '@mui/material/Box';

export default function MyAppBar(props: any) {
  return (
    <Box >
      <AppBar position="fixed">
        <Toolbar>
          <Typography id="title" variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {props.title}
          </Typography>
        </Toolbar>
      </AppBar>
    </Box>
  );
}