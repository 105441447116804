import { useState, useEffect } from 'react';

import style from './ScoreBoard.module.css';
import hourglass from '../assets/img/score/hourglass.png';
import medaille from '../assets/img/score/medaille.png';
import scoreIcon from '../assets/img/score/score.png';
import sound from '../assets/sounds/game-over.mp3';
import { deleteCookie, getCookie } from '../urbanlock';
import ConfettiExplosion from 'react-confetti-explosion';


const cookiesToPurges = [
    'new_message',
    'new_tool',
    'warn_user_hint_are_impacting_final_score',
    'phone_has_been_unlocked',
    'private_messages_unlocked',
    'cctv_has_been_unlocked'
]

const purgeCookie = (cookies: String[]) => {
    cookies.forEach((cookie) => {
        if (getCookie(cookie)) {
            deleteCookie(cookie)
        }
    })
}

const convertSecondsToHMS = (seconds: number) => {
    if (seconds < 0) {
        throw new Error("Input must be a non-negative number");
    }

    const hours: number = Math.floor(seconds / 3600);
    const minutes: number = Math.floor((seconds % 3600) / 60);
    // const remainingSeconds: number = seconds % 60;
    if (hours < 1) {
        return ` ${minutes} min`
    } else {
        return `${hours}h ${minutes} min`

    }
};

const computeDuration = (start: number, end: number) => {
    const total: number = end - start
    return convertSecondsToHMS(total)
}




function ScoreBoard() {
    const gameCode = getCookie('game-code');
    const [name, setName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [duration, setDuration] = useState<string>("");
    const [points, setPoints] = useState<string>("");
    const [score, setScore] = useState<string>("");
    const [linkGameEmail, setLinkGameEmail] = useState<string>("");
    const [percentageTopPlayers, setPercentageTopPlayers] = useState<number>(0);

    purgeCookie(cookiesToPurges)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_FINISH_GAME}?game=${gameCode}`);
                const data = await response.json();
                setName(data.name);
                setEmail(data.email);
                setDuration(computeDuration(data.start_time, data.end_time));
                setPoints(data.points);
                setPercentageTopPlayers(Math.ceil((data.points / 100) - 4));
                setScore(data.score);
                const audio = new Audio(sound);
                audio.loop = false;
                audio.play();
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [gameCode]); // Empty dependency array ensures the effect runs only once on mount



    // Confetti config
    const confettiConfig = {
        force: 0.8,
        duration: 5000,
        particleCount: 250,
        width: 1600,
    }

    const [isExploding] = useState(true);


    const handleLinkToEmail = () => {

        fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URI_SUPPORT}?game=${gameCode}&code=${process.env.REACT_APP_AZURE_FUNCTION_CODE}`, {
            method: "POST", body: JSON.stringify(
                {
                    message: `Link this game to this email : ${linkGameEmail}`,
                    gameCode: gameCode
                }
            )
        }).then(data => {
            if (data.status === 200) {
                // setGgSnack(true)
                setLinkGameEmail("")
                // setLoading(false)
            }
        })
    }




    return (
        <>
            <div className={style.container}>
                <span className={style.title}>{name}</span>
                <span className={style.email}>{email}</span>
                <span className={style.rank}>Aventurier en herbe</span>
                <span className={style.title}>Mystère Nocturne</span>
                <div className={style.scoreDetails}>
                    {isExploding && <ConfettiExplosion style={{ margin: "auto" }} {...confettiConfig} />}
                    <div className={style.line}>
                        <div className={style.element}>
                            <img alt='' src={medaille} />
                            <span>+{points} points</span>
                        </div>
                        <span className={style.annotation}>Félicitations ! Vos points s'accumulent
                            d'aventures en aventures pour vous permettre
                            de débloquer de nombreuses surprises 😎</span>
                    </div>

                    <div className={style.line}>
                        <div className={style.element}>
                            <img alt='' src={scoreIcon} />
                            <span>{score}</span>
                        </div>
                        {percentageTopPlayers > 50 ?
                            (<span className={style.annotation}>Vous avez fait mieux que {percentageTopPlayers}% des équipes.
                                Vous pouvez êtres fier de vous !</span>) : (
                                <span className={style.annotation}>Bravo pour votre performance, vous avez fait fort! 💪</span>)
                        }
                    </div>

                    <div className={style.line}>
                        <div className={style.element}>
                            <img alt='' src={hourglass} />
                            <span>{duration}</span>
                        </div>
                        <span className={style.annotation}>Bravo - je suis sûr que vous n’avez pas vu
                            le temps passer !</span>
                    </div>
                </div>

                <div className={style.separator}></div>
                <span className={style.title}>Lier ce score à ton profil ?</span>
                <span className={style.regular}>Saisis ton adresse email pour lier ce score à TON profil et pas uniquement à celui de {name} ! 😜</span>
                <div className={style.form}>
                    <input placeholder="ton-adresse@mail.com" type='email' value={linkGameEmail} onChange={(e) => setLinkGameEmail(e.target.value)} />
                    <button onClick={handleLinkToEmail}>Me lier à cette partie</button>
                </div>

            </div>
            <br />
        </>
    );
}

export default ScoreBoard;
