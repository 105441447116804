import React from "react";
import Pinpad from './Pinpad';
import UnlockedMessages from './UnlockedMessages'
import { getCookie } from "../urbanlock";


export default function InPhoneMessages() {

    const [unlockedMessages, setUnlockedMessages] = React.useState(getCookie("private_messages_unlocked") || false);



    return (
        <>
            {unlockedMessages ? <UnlockedMessages /> : <Pinpad setUnlockedMessages={setUnlockedMessages} />}
        </>
    );
}
