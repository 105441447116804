import Webcam from "react-webcam";
import "./Webcam.css"
import PhoneCameraOverlay from '../assets/img/tools/phone/camera/overlay.png'


const Overlay = () => (
    <div className="overlay">
        <img alt="overlay" src={PhoneCameraOverlay} />
    </div>
);

const videoConstraints = {
    facingMode: { exact: "environment" },
    width: { min: 100 },
    height: { min: 100 },
    aspectRatio: 2
}



const WebcamComponent = () => (
    <div className="webcam-container">
        <Webcam videoConstraints={videoConstraints} />
    </div>
);


export default function EnvWebcam() {


    return (

        <>          
            <div className="webcam-fullscreen">
                <WebcamComponent />
                <Overlay />
            </div>
        </>
    );
}