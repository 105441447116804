import * as React from 'react';
import Box from '@mui/material/Box';
import "./ToolCard.css"
import { useNavigate } from "react-router-dom";

export default function ToolCard(props: any) {
  const navigate = useNavigate();

  const handleSubmit = () => {    
    // You can perform any actions here and navigate if needed.
    navigate(`/outils/${props.title}`);
  };

  return (
    <Box id="toolCard" onClick={handleSubmit}>
      <img src={props.image} alt={props.title} />
      <div id="right-panel">
        <p id="title">{props.title}</p>
        <p id="description">{props.description}</p>
      </div>
    </Box>
  );
}
