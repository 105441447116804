import Grid from '@mui/material/Grid';
import Chat from './Chat';
import './MessagesGrid.css'
import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import { getCookie, firebaseConfig } from "../urbanlock"
import LoadingButton from '@mui/lab/LoadingButton';

const gameCode = getCookie("game-code")



const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const chatsRef = ref(db, `${gameCode}/chats/`);

interface ChatInterface {
  id: string;
  name: string;
  image: string;
  lastMessage: string;
  isBold: boolean;
}

function SingleColumnGrid(props: any) {

  const [chatsList, setChatsList] = useState<any[]>([]);
  const [newContact, SetNewContact] = useState('');


  useEffect(() => {
    onValue(chatsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const chatsList: ChatInterface[] = Object.keys(data).map((chatsId) => (
          {
            id: chatsId,
            name: data[chatsId].name,
            image: data[chatsId].image,
            lastMessage: data[chatsId].lastMessage,
            isBold: false
          }));
        setChatsList(chatsList);
      }
    });
  }, []);

  const [open, setOpen] = React.useState(false);
  const [errorSnackOpen, setErrorSnackOpen] = React.useState(false);
  const [successSnackOpen, setSuccessSnackOpen] = React.useState(false);
  const [buttonDisabled, setButtonDisabled] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("Ce numéro n'existe pas !");
  const [loading, setLoading] = React.useState(false);
  const [loadingText, setLoadingText] = React.useState("Ajouter");
  // const navigate = useNavigate();

  const NewChathandleClickOpen = () => {
    setOpen(true);
  };

  const NewChathandleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(false);
  };

  const NewChathandleSubmit = (event: any) => {
    event.stopPropagation();
    setLoading(true)
    setLoadingText("...")
    if (newContact !== "") {
      fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEW_CHAT}?game=${gameCode}&number=${newContact}`).then(data => {
        if (data.status === 200) {
          setSuccessSnackOpen(true)
          setOpen(false)
          SetNewContact("")
          fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=scrabble`).then(data => {
            if (data.status !== 404) {
              fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=note-secrete`).then(data => {
                if (data.status !== 404) {
                  // adding new hints
                  fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEWHINT}?game=${gameCode}&hint=photo-theatre`).then(data => {
                  })
                  fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEWHINT}?game=${gameCode}&hint=origami`).then(data => {
                  })
                }
              })
            }
          })
        } else {
          SetNewContact("")
          setButtonDisabled(false)
          setErrorSnackOpen(true)
          setErrorMessage("Ce numéro n'existe pas !")
          fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_ADD_MALUS}?game=${gameCode}&malus=50`).then(data => { })
        }
      })
    } else {
      SetNewContact("")
      setButtonDisabled(false)
      setErrorSnackOpen(true)
      setErrorMessage("Veuillez saisir un numéro!")
    }
    setLoading(false)
    setLoadingText("Ajouter")
  }


  const handleChange = (event: any) => {
    if (event.nativeEvent.inputType === "deleteContentBackward") {
      setButtonDisabled(true)
      if (newContact.length === 3 || newContact.length === 6 || newContact.length === 9 || newContact.length === 12) {
        let val = newContact
        SetNewContact(val.substring(0, val.length - 2));
      } else {
        let val = newContact
        SetNewContact(val.substring(0, val.length - 1));
      }
    } else {
      if (!isNaN(event.nativeEvent.data)) {
        if (newContact.length < 14) {
          if (newContact.length === 2 || newContact.length === 5 || newContact.length === 8 || newContact.length === 11) {
            let val = `${newContact}-${event.nativeEvent.data}`
            SetNewContact(val);
          } else {
            SetNewContact(event.target.value);
            if (event.target.value.length === 14) {
              setButtonDisabled(false)
            }
          }
        }
      }
    }
  }

  return (
    <>
      <Grid container sx={{ marginBottom: '100px' }}>

        {chatsList.map((chat) => (
          <Grid item xs={12} sx={{ padding: '5px' }} key={chat.id}>
            <Chat isBold={getCookie("new_message") === chat.id ? true : false} name={chat.name} image={chat.image} lastMessage={chat.lastMessage} id={chat.id} />
          </Grid>
        ))}
      </Grid>
      <div id='newChat' onClick={NewChathandleClickOpen}>+ Nouveau Chat</div>
      <Dialog
        open={open}
        onClose={NewChathandleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        id="newNumDialog"
      >
        <DialogTitle id="alert-dialog-title">
          {"Numéro du contact"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component={'div'} id="alert-dialog-description">
            <TextField autoComplete='off' id="newChatInput" placeholder="XX-XX-XX-XX-XX" type="text" value={newContact} onChange={handleChange} inputProps={{ inputMode: 'numeric' }} />
          </DialogContentText>
        </DialogContent>
        <DialogActions id="buttonBox">
          <LoadingButton
            color="secondary"
            onClick={NewChathandleSubmit}
            loading={loading}
            variant="contained"
            disabled={buttonDisabled}
            id="confirm"
          >
            <span>{loadingText}</span>
          </LoadingButton>


        </DialogActions>
      </Dialog>
      <Snackbar open={errorSnackOpen} autoHideDuration={3000} onClose={() => setErrorSnackOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={successSnackOpen} autoHideDuration={3000} onClose={() => setSuccessSnackOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="success" sx={{ width: '100%' }}>
          Nouveau contact ajouté !
        </Alert>
      </Snackbar>
    </>
  );
}

export default SingleColumnGrid;