import React, { useState, useEffect } from "react";
import PatternLock from "react-pattern-lock";

import { getCookie, setCookie } from "../urbanlock"


const Demo = (props: any) => {
  const [path, setPath] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [almost, setAlmost] = useState(false);
  const [size] = useState(3);

  const gameCode = getCookie("game-code");


  // eslint-disable-next-line
  let errorTimeout = 0;

  const onReset = () => {
    setPath([]);
    setSuccess(false);
    setError(false);
    setDisabled(false);
  };

  const onChange = (newPath: any) => {
    setPath([...newPath]);
  };

  const onFinish = () => {
    setAlmost(false)
    setIsLoading(true);
    // Simulate an imaginary API call
    setTimeout(() => {

      if ((path.join("-") === "7-4-1-3-0-5") || (path.join("-") === "7-4-1-3-0-8")) {
        setAlmost(true)
      }

      if (path.join("-") === "7-4-1-3-0-2") {

        // we made it in cascade to avoid new hint to be added everytime the phone got unlocked
        // that's crap as fuck but hey oh that's a prototype

        // removing cinéma, smartphone, médaillon et carte postale
        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=carte-postale`).then(data => {
          if (data.status !== 404) {
            fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=ticket-cinéma`).then(data => {
              if (data.status !== 404) {
                fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=smartphone`).then(data => {
                  if (data.status !== 404) {
                    fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_REMOVE_HINT}?game=${gameCode}&hint=medaillon`).then(data => {
                      if (data.status !== 404) {
                        // adding new hints
                        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEWHINT}?game=${gameCode}&hint=note-secrete`).then(data => {
                        })
                        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_NEWHINT}?game=${gameCode}&hint=scrabble`).then(data => {
                        })
                      }
                    })
                  }
                })
              }
            })
          }
        })
        setCookie("phone_has_been_unlocked", true, 1)
        props.setUnlocked(true)
        setIsLoading(false);
        setSuccess(true);
        setDisabled(true);
      } else {
        setDisabled(true);
        setError(true);
        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_ADD_MALUS}?game=${gameCode}&malus=50`).then(data => { })
        errorTimeout = window.setTimeout(() => {
          setDisabled(false);
          setError(false);
          setIsLoading(false);
          setPath([]);
        }, 1000);
      }
    }, 500);
  };

  useEffect(() => {
    // You can add any side effects here if needed
  }, []); // Empty dependency array means this effect runs once, similar to componentDidMount

  return (
    <React.Fragment>
      <h2 style={{ color: "#FFF", textAlign: "center", fontFamily: "sans-serif", marginTop: "100px" }}>Dessiner le modele de déverrouillage</h2>
      {almost && <h5 style={{ color: "#D44841", textAlign: "center", fontFamily: "sans-serif" }}>Erreur sur le dernier point !</h5>}
      <div className="center" style={{ marginTop: "90px" }}>
        <PatternLock
          size={size}
          onChange={onChange}
          path={path}
          error={error}
          onFinish={onFinish}
          connectorThickness={5}
          width={"75%"}
          disabled={disabled || isLoading}
          success={success}
          style={{ margin: "auto" }}
        />
      </div>
      {success && (
        <button
          style={{ margin: "0 auto", display: "block" }}
          onClick={onReset}
        >
          Click here to reset
        </button>
      )}
    </React.Fragment>
  );
}

export default Demo;
