import Image from '../assets/img/404.png'
import './NoPage.css'
import { useNavigate } from "react-router-dom";

function NoPage() {
  let navigate = useNavigate();
    return (
      <div id="noPage">
        <img src={Image} alt=""/>
        <h1>La page n'a pas répondu.</h1>
        <p>La page demandé n'existe pas ou est tombé en panne. <br />On retourne quelque part où ça marche ?</p>
        <button onClick={() => navigate(-1)}>Retour en lieu sûr !</button>
      </div>
    );
  }

export default NoPage;