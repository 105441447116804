import LockScreen from '../pages/LockScreen';
import NoteApp from '../pages/NoteApp';
import React, { useState, useEffect } from 'react';
import Battery from '../assets/img/Battery.png';
import styles from "./Phone.module.css";
import EnvWebcam from '../components/Webcam';
import CallLog from './CallLog';
import InPhoneMessages from './InPhoneMessages';
import { getCookie } from '../urbanlock';

export const DateTime = () => {
    var [date, setDate] = useState(new Date());

    useEffect(() => {
        var timer = setInterval(() => setDate(new Date()), 1000);
        return function cleanup() {
            clearInterval(timer);
        };
    });

    return (
        <div>
            <p id="time">{date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</p>
        </div>
    );
};

const MenuOption: React.FC<MenuOptionProps> = (props) => {
    const { title, setApp } = props;

    function switchMenu(menu: string) {
        setApp(menu);
    }

    return (
        <div onClick={() => switchMenu(title)}>
            {icons[title]}
        </div>
    );
}

type MenuOptionProps = {
    title: IconType;
    setApp: (menu: string) => void; // Add the setApp prop type
};

export const BottomMenu = ({ setApp }: { setApp: (menu: string) => void }) => {
    return (
        <div className={styles.bottomMenu}>
            <MenuOption setApp={setApp} title="notes" />
            <MenuOption setApp={setApp} title="messages" />
            <MenuOption setApp={setApp} title="journal d'appel" />
            <MenuOption setApp={setApp} title="camera" />
        </div>
    );
};

type IconType = 'camera' | 'notes' | 'messages' | 'journal d\'appel';

const icons: { [key in IconType]: JSX.Element } = {
    camera: (
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M149.1 64.8L138.7 96H64C28.7 96 0 124.7 0 160V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V160c0-35.3-28.7-64-64-64H373.3L362.9 64.8C356.4 45.2 338.1 32 317.4 32H194.6c-20.7 0-39 13.2-45.5 32.8zM256 192a96 96 0 1 1 0 192 96 96 0 1 1 0-192z" />
        </svg>
    ),
    notes: (
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512">
            <path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H288V368c0-26.5 21.5-48 48-48H448V96c0-35.3-28.7-64-64-64H64zM448 352H402.7 336c-8.8 0-16 7.2-16 16v66.7V480l32-32 64-64 32-32z" />
        </svg>
    ),
    messages: (
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M64 0C28.7 0 0 28.7 0 64V352c0 35.3 28.7 64 64 64h96v80c0 6.1 3.4 11.6 8.8 14.3s11.9 2.1 16.8-1.5L309.3 416H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H64z" />
        </svg>
    ),
    "journal d'appel": (
        <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512">
            <path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" />
        </svg>
    ),
};

export const UnlockedScreen = () => {
    var [app, setApp] = useState("camera");

    let displayedApp;
    if (app === "notes") {
        displayedApp = <NoteApp />
    }
    if (app === "messages") {
        displayedApp = <InPhoneMessages />
    }
    if (app === "camera") {
        displayedApp = <EnvWebcam />
    }
    if (app === "journal d'appel") {
        displayedApp = <CallLog />
    }

    return (
        <>
            {displayedApp}
            <BottomMenu setApp={setApp} />
        </>
    )
};

export default function Phone(params: any) {
    const [unlocked, setUnlocked] = useState(getCookie("phone_has_been_unlocked") || false);
    return (
        <div id="phone">
            <div id="phone-top">
                <DateTime />
                <img alt="battery-charge" id="battery" src={Battery} width="30" />
            </div>
            <div style={{ height: "80%", overflow: "scroll" }}>
                {unlocked ? <UnlockedScreen /> : <LockScreen setUnlocked={setUnlocked} />}
            </div>
        </div>
    );
}
