import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Badge, { BadgeProps } from "@mui/material/Badge";
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import ForumIcon from '@mui/icons-material/Forum';
import KeyIcon from '@mui/icons-material/Key';
import { styled } from "@mui/material/styles";
import { Link, useLocation } from 'react-router-dom';
import './BottomNavigation.css';

import { getCookie } from "../urbanlock";

export default function SimpleBottomNavigation() {
  const location = useLocation();
  const [value, setValue] = useState(location.pathname.substring(1));
  const [messageIcon, setMessageIcon] = useState(<ForumIcon />);
  const [toolIcon, setToolIcon] = useState(<KeyIcon />);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: -3,
      top: 13,
      border: `2px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  }));

  useEffect(() => {
    if (getCookie("new_message")) {
      setMessageIcon(
        <StyledBadge badgeContent={1} color="secondary">
          <ForumIcon />
        </StyledBadge>
      );
    } else {
      setMessageIcon(<ForumIcon />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run the effect only once after the initial render


  useEffect(() => {
    if (getCookie("new_tool")) {
      setToolIcon(
        <StyledBadge badgeContent={1} color="secondary">
          <KeyIcon />
        </StyledBadge>
      );
    } else {
      setToolIcon(<KeyIcon />);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Empty dependency array to run the effect only once after the initial render

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
    if (newValue === "messages") {
      setMessageIcon(<ForumIcon />);
    }
    if (newValue === "outils") {
      setToolIcon(<KeyIcon />);
    }
  };

  return (
    <Box id="bottomMenu" sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 999 }}>
      <BottomNavigation value={value} onChange={handleChange}>
        <BottomNavigationAction
          label="Indices"
          icon={<LightbulbIcon />}
          component={Link}
          to="/indices"
          value="indices"
        />
        <BottomNavigationAction
          label="Messages"
          icon={messageIcon}
          component={Link}
          to="/messages"
          value="messages"
        />
        <BottomNavigationAction
          label="Outils"
          icon={toolIcon}
          component={Link}
          to="/outils"
          value="outils"
        />
      </BottomNavigation>
    </Box>
  );
}
