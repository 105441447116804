const NotePreview = (props: any) => {
    const { onClick } = props;

    const card = {
        color: "#FFF",
        backgroundColor: props.color,
        margin: "10px",
        width: "100px",
        height: "100px",
        display: "inline-block",
        boxShadow: "2px 2px 5px black"
    };
    const title = {
        display: "block",
        marginTop: "10px",
        fontSize: "14px",        
        fontWeight: "bold",
        whiteSpace: "nowrap" as const,
        overflow: "hidden",
        textOverflow: "ellipsis",
        padding:"3px"
    };
    const content = {
        padding: "5px",
        marginLeft: "5px",
        whiteSpace: "nowrap" as const,
        overflow: "hidden",
        textOverflow: "ellipsis"

    };

    const handleClick = () => {
        onClick(props.index); 
    };
    return (
        <div style={card} onClick={handleClick}>
            <span style={title}>{props.title}</span>
            <p style={content}>{props.content}</p>
        </div>
    )
};

export default NotePreview;