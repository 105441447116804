import { useState, useEffect } from 'react';
import { HashRouter, Routes, Route, Navigate } from 'react-router-dom';
import Hints from './pages/Hints';
import Messages from './pages/Messages';
import Tools from './pages/Tools';
import Layout from './pages/Layout';
import NoPage from './pages/NoPage';
import MessagesDetails from './pages/MessagesDetails';
import ToolsDetails from './pages/ToolsDetails';
import HintsDetails from './pages/HintsDetails';
import Login from './pages/Login';
import Support from './components/Support'
import './App.css';
import BareLayout from './pages/BareLayout';
import { getCookie, Game } from './urbanlock';
import Loading from './pages/Loading';
import ScoreBoard from './pages/ScoreBoard';

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const gameCode = getCookie('game-code');


  useEffect(() => {
    const runGame = async (code: string) => {
      setLoading(true);
      const game = new Game(code);
      await game.startNew();
      if (game.data) {
        setAuthenticated(true);
      } else {
        setAuthenticated(false);
      }
      setLoading(false);
    };

    if (gameCode) {
      runGame(gameCode);
    }
  }, [gameCode, authenticated]); // Only re-run if gameCode or authenticated changes


  if (loading) {
    return <Loading />;
  } else {
    if (authenticated) {
      return (
        <HashRouter>
          <Routes>
            <Route path="/" element={<Navigate to="messages" />} />
            <Route path="/" element={<BareLayout />}>
              <Route path="indices/:indices" element={<HintsDetails />} />
              <Route path="messages/:messages" element={<MessagesDetails />} />
              <Route path="outils/:outils" element={<ToolsDetails />} />
              <Route path="*" element={<NoPage />} />
              <Route path="score" element={<ScoreBoard />} />
              <Route path="support" element={<Support />} />
            </Route>
            <Route path="/" element={<Layout />}>
              <Route path="messages" element={<Messages />} />
              <Route path="indices" element={<Hints />} />
              <Route path="outils" element={<Tools />} />
            </Route>
          </Routes>
        </HashRouter>
      );
    } else {
      return <Login />;
    }
  }
}

export default App;
