import './Login.css'
import { useState, createRef, useEffect } from "react";
import { Game } from "../urbanlock"
import Loader from '../assets/img/loader.gif'
import Reverted from '../assets/img/reverted.png'



function Login() {

    const numerOfInputs = 6;
    const [inputRefsArray] = useState(() =>
        Array.from({ length: numerOfInputs }, () => createRef())
    );

    async function runGame(code: string) {
        setHeroLogo(Loader)
        const game = new Game(code);
        await game.startNew()
        if (game.data) {
            setHeroLogo(Reverted)
            window.location.reload()
        } else {
            setErrorCounter(errorCounter + 1)
            setInError(true)
            setButtonIsDisabled(true)
            setHeroLogo(Reverted)
            setLetters(Array.from({ length: numerOfInputs }, () => ""))
            await new Promise(r => setTimeout(r, 1000)).then(e => {
                setInError(false)
            })
        }
    }



    const submitCode = () => {
        const code = letters.join("");
        runGame(code);
    }



    const [currentIndex, setCurrentIndex] = useState(0);
    const [heroLogo, setHeroLogo] = useState(Reverted)
    const [buttonIsDisabled, setButtonIsDisabled] = useState(true)
    const [inError, setInError] = useState(false)
    const [errorCounter, setErrorCounter] = useState(0)
    const [letters, setLetters] = useState(() =>
        Array.from({ length: numerOfInputs }, () => "")
    );

    useEffect(() => {
        const code = letters.join("");
        setButtonIsDisabled(code.length !== 6);
    }, [letters]);

    useEffect(() => {
        // focus the first input initially
        if (inputRefsArray?.[0]?.current) {
            (inputRefsArray?.[0]?.current as HTMLElement)?.focus();
        }

        // remove the event listener when the component unmounts
        return () => {
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="main" style={{ height: errorCounter === 3 ? '110vh' : '100vh' }}>
            <img className="login-img" alt="urbanlock-logo" id="logo" src={heroLogo} />
            <div className="content">
                <h1 className="white-text login-title">Code de partie</h1>
                <p className="yellow-text login-p">Saisis le code présent dans ton kit de démarrage</p>
                <div className="code">
                    {inputRefsArray.map((ref: any, index) => {
                        return (
                            <input
                                className={inError ? "shake" : ""}
                                style={{ border: inError ? "4px solid red" : "", paddingLeft:'10px', paddingRight:'10px' }}
                                autoComplete="off"
                                ref={ref}
                                type="text"
                                key={`box${index}-1`}
                                id={`box${index}-1`}
                                onKeyDown={(e) => {
                                    if (e.key === 'Backspace') {
                                        setButtonIsDisabled(true)
                                        setLetters((letters) =>
                                            letters.map((letter, letterIndex) =>
                                                letterIndex === index ? "" : letter
                                            )
                                        );
                                        setLetters((letters) =>
                                            letters.map((letter, letterIndex) =>
                                                letterIndex === index - 1 ? "" : letter
                                            )
                                        );
                                        setCurrentIndex((prevIndex) => {
                                            if (currentIndex !== 0) {
                                                // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
                                                const nextIndex = prevIndex < numerOfInputs + 1 ? prevIndex - 1 : 0;
                                                const nextInput: any = inputRefsArray?.[nextIndex]?.current;
                                                nextInput.focus();
                                                nextInput.select();
                                                return nextIndex;
                                            } else {
                                                return 0
                                            }
                                        });
                                    }
                                }}
                                onChange={(e) => {
                                    const value = e.currentTarget.value
                                    if (value.match(/^[A-Za-z0-9]*$/) && value.length === 1) {
                                        setLetters((letters) =>
                                            letters.map((letter, letterIndex) =>
                                                letterIndex === index ? value : letter
                                            )
                                        );
                                    }
                                        // let code = ""
                                        // inputRefsArray.forEach(element => {
                                        //     code += (element.current as HTMLInputElement).value
                                        // });
                                        if (currentIndex !== 5) {
                                            setButtonIsDisabled(true)
                                            setCurrentIndex((prevIndex) => {
                                                // calculate the next input index, next input after the final input will be again the first input. you can change the logic here as per your needs
                                                const nextIndex = prevIndex < numerOfInputs - 1 ? prevIndex + 1 : 0;
                                                const nextInput: any = inputRefsArray?.[nextIndex]?.current;
                                                nextInput.focus();
                                                nextInput.select();
                                                return nextIndex;
                                            });
                                        }
                                    
                                }}
                                onClick={(e) => {
                                    setCurrentIndex(index);
                                    const target = e.target as HTMLTextAreaElement;
                                    target.select();
                                }}
                                value={letters[index]}
                                max={"1"}

                            />
                        );
                    })}
                </div>
                <p><a href="https://urbanlock.fr" className="green-text text-right">Je n'ai pas de code</a></p>

                <button disabled={buttonIsDisabled} className="login-button" onClick={submitCode}>Démarrer l'aventure*</button>

                <p className="white-text">* Attention le chrono démarre quand tu cliques ! (Pense à activer le son 🔊!)</p>
                <div id="error" style={{ display: errorCounter === 3 ? 'block' : 'none' }}>
                    <a href="https://urbanlock.fr/contact">
                        <span>Il semblerait que ton code soit invalide.<br />
                            Contacter notre support ?</span></a>
                </div>
                <p id="tutorial">
                    Première fois ? <a href="https://urbanlock.fr">Consulte le tutoriel 🏁</a>
                </p>
            </div>
        </div>
    );
}

export default Login;