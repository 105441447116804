import Box from '@mui/material/Box';
import MyAppBar from './AppBar';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from 'react';
import SendIcon from '@mui/icons-material/ArrowForwardIos';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../urbanlock';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


export default function Support(props: any) {
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const [ggSnack, setGgSnack] = useState(false)

  const navigate = useNavigate();

  const gameCode = getCookie("game-code")


  const handleSubmit = () => {
    setLoading(true)


    fetch(`${process.env.REACT_APP_AZURE_FUNCTION_URI_SUPPORT}?game=${gameCode}&code=${process.env.REACT_APP_AZURE_FUNCTION_CODE}`, {
      method: "POST", body: JSON.stringify(
        {
          message: message,
          gameCode: gameCode
        }
      )
    }).then(data => {
      if (data.status === 200) {
        setGgSnack(true)
        setMessage("")
        setLoading(false)
      }
    })
  }

  return (
    <Box>
      <MyAppBar title="Rapporter un bug" />
      <div style={{ marginTop: '60px', padding: '20px' }}>
        <TextField
          value={message}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.target.value)}
          minRows={15}
          variant="outlined"
          multiline
          fullWidth
          label="Qu'est ce qu'il se passe ?"
          id="messageInput"
          focused />
        <br />
        <br />
        <Stack justifyContent="center"
          alignItems="center" bottom={30} spacing={2} direction="row">
          <LoadingButton
            id="confirm"
            color="secondary"
            loading={loading}
            onClick={handleSubmit}
            loadingPosition="start"
            startIcon={<SendIcon />}
          >
            <span>Envoyer</span>
          </LoadingButton>
          <Button variant='outlined' onClick={() => { navigate(-1) }}>
            Retour
          </Button>
        </Stack>
      </div>

      <Snackbar open={ggSnack} autoHideDuration={5000} onClose={() => setGgSnack(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
        <Alert severity="info" sx={{ width: '100%' }}>
          Votre message a été envoyé. Merci de votre aide !
        </Alert>
      </Snackbar>

    </Box>
  );
}
