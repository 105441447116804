import style from './CallLog.module.css'
import erika from '../assets/img/tools/phone/callLog/erika.jpg'
import simon from '../assets/img/tools/phone/callLog/simon.jpg'
import maxime from '../assets/img/tools/phone/callLog/maxime.jpg'
import pizzaKing from '../assets/img/tools/phone/callLog/pizzaKing.jpg'


const Line = ({ name, duration, date, picture }: { name: string; duration: string; date: string; picture: string }) => {
    return (
        <div className={style.logLine}>
            <img alt="profilePicture" className={style.picture} src={picture} />
            <div className={style.left}>
                <p className={style.name}>{name}</p>
                <p className={style.date}>{date}</p>
            </div>
            <div className={style.right}>
                <span className={style.duration}>{duration}</span>
            </div>
        </div>
    )
}


const dateOfDaysAgo = (daysAgo: number) => {
    const months = [
        'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
        'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
    ];

    const today = new Date();
    today.setDate(today.getDate() - daysAgo);

    const day = today.getDate();
    const monthIndex = today.getMonth();

    const formattedDate = `${day < 10 ? '0' : ''}${day} ${months[monthIndex].substring(0, 3)}.`;
    return formattedDate
}




const contacts = [
    {
        name: "Erika",
        duration: "7 min.",
        date: "↗ hier à 23h02",
        picture: erika
    }, {
        name: "Erika",
        duration: "53 min.",
        date: "↙ hier à 15h17",
        picture: erika
    },
    {
        name: "Simon",
        duration: "41 min.",
        date: "↗ hier à 13h34",
        picture: simon
    }, {
        name: "Erika",
        duration: "47 min.",
        date: `↙ ${dateOfDaysAgo(2)} à 23h56`,
        picture: erika
    },
    {
        name: "Pizza King",
        duration: "6 min.",
        date: `↗ ${dateOfDaysAgo(2)} à 19h12`,
        picture: pizzaKing
    }, {
        name: "Max",
        duration: "37 min.",
        date: `↗ ${dateOfDaysAgo(3)} à 18h09`,
        picture: maxime
    },
    {
        name: "Erika",
        duration: "3 min.",
        date: `↙ ${dateOfDaysAgo(3)} à 09h27`,
        picture: erika
    }
]



export default function CallLog() {
    return (
        <>
            <h1 style={{ color: "#FFF", textAlign: "center", marginTop: 0 }}>Journal d'appel</h1>
            <div className={style.mainContainer}>
                {contacts.map((contact, index) => (
                    // Render a ContactItem for each contact in the list
                    <Line
                        key={index} // Make sure to set a unique key for each item
                        name={contact.name}
                        duration={contact.duration}
                        date={contact.date}
                        picture={contact.picture}
                    />
                ))}
            </div>
        </>
    );
}
