import { useNavigate, Outlet, useParams } from "react-router-dom";
import { initializeApp } from "firebase/app";
import React, { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from "firebase/database";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/ArrowForwardIos';


import './HintsDetails.css'
import { getCookie, setCookie, firebaseConfig } from "../urbanlock"
import LeftArrow from '../assets/img/leftArrow.svg'


const gameCode = getCookie("game-code")

const Layout = () => {
    const params = useParams();
    let navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [image, setImage] = useState("");
    const [steps, setSteps] = useState("");
    const [currentText, setCurrentText] = useState("")
    const [currentImage, setCurrentImage] = useState("")
    const [currentStep, setCurrentStep] = useState("")
    const [currentButtonText, setCurrentButtonText] = useState("")
    const [hints, setHints] = useState<any[]>([]);
    const [open, setOpen] = React.useState(false);
    const [hintId] = useState(JSON.stringify(params.indices).slice(1, -1));

    const [loading, setLoading] = useState(false);

    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const hintsRef = ref(db, `${gameCode}/hints/` + hintId);


    useEffect(() => {
        const hintsListener = onValue(hintsRef, (snapshot) => {
            const data = snapshot.val();
            setTitle(data.title);
            setImage(data.image);
            setSteps(data.number_of_steps);
            const filteredHints = Object.keys(data)
                .filter((key) => Number.isInteger(parseInt(key, 10)))
                .map((key) => data[key]);

            setHints(filteredHints);

            // utile pour faire le caroussel
            setCurrentText(filteredHints[0].text)
            setCurrentStep("1")
            filteredHints[0].image != null ? setCurrentImage(filteredHints[0].image) : setCurrentImage(data.image)

            // utile pour afficher le dernier indice dévérouiller à tout le monde d'un coup
            // setCurrentStep(filteredHints.length.toString())
            // setCurrentText(filteredHints.at(-1).text)
            // filteredHints.at(-1).image != null ? setCurrentImage(filteredHints.at(-1).image) : setCurrentImage(data.image)


            if (filteredHints.length === 1) {
                setCurrentButtonText("Déverouiller indice suivant 🔒")
            } else {
                setCurrentButtonText("Voir l'indice suivant")
            }


        });
        return () => {
            hintsListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setOpen(false);
    };

    const handleSubmit = () => {
        setLoading(true);
        setOpen(false);
        fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_HINT}?game=${gameCode}&hint=${hintId}&step=${currentStep}`).then(response => response.json()).then(data => {
            setCurrentStep((parseInt(currentStep) + 1).toString())
            setCurrentText(data.text)
            if (data.image == null) {
                setCurrentImage(image)
            } else {
                setCurrentImage(data.image)
            }

            if ((parseInt(currentStep) + 1) === parseInt(steps)) {
                setCurrentButtonText("Retour au jeu")
            } else {
                setCurrentButtonText("Déverouiller indice suivant 🔒")
            }

            setLoading(false);


        })
    }


    const handleButtonClick = () => {

        // si l'étape est la dernière disponible, on revient à la page précedente
        if (parseInt(currentStep) === parseInt(steps)) {
            navigate(-1)
        }


        // si l'indice courant est le dernier débloqué mais pas la dernière step
        if (parseInt(currentStep) === hints.length && parseInt(currentStep) !== parseInt(steps)) {
            if (getCookie('warn_user_hint_are_impacting_final_score')) {
                handleSubmit()
            } else {
                setOpen(true);
                setCookie('warn_user_hint_are_impacting_final_score', true, 1);
            }
        }

        // si l'étape courante n'est pas la dernière débloqué
        if (parseInt(currentStep) !== hints.length) {
            // on incrémente le compteur en bas
            setCurrentStep((parseInt(currentStep) + 1).toString())
            // on change le texte par le texte de l'indice suivant
            setCurrentText(hints[parseInt(currentStep)].text)
            if (hints[parseInt(currentStep)].image != null) {
                // si la step a une image particulière on l'affiche
                setCurrentImage(hints[parseInt(currentStep)].image)
            } else {
                // si on fallback sur l'image par défault
                setCurrentImage(image)
            }
            // si la step suivante est pas encore débloqué
            if (parseInt(currentStep) + 1 === hints.length) {
                setCurrentButtonText("Déverouiller indice suivant 🔒")
                // si on est sur la dernière step de l'indice
                if (hints.length === parseInt(steps)) {
                    setCurrentButtonText("Retour au jeu")
                }
            }




        }

    };


    return (
        <>

            <div id="header">
                <img alt="" onClick={() => navigate(-1)} id="leftArrow" src={LeftArrow} />
                <p id="title">{title}</p>
            </div>

            <div id="content">
                <img alt="" src={currentImage} />
                <p id="description">{currentText}</p>

                <LoadingButton
                    color="secondary"
                    onClick={handleButtonClick}
                    loading={loading}
                    loadingPosition="start"
                    startIcon={<SendIcon />}
                    variant="contained"
                >
                    <span>{currentButtonText}</span>
                </LoadingButton>


                {/* <LoadingButton
                    size="small"
                    color="secondary"
                    onClick={handleButtonClick}
                    loading={loading}
                    // loadingPosition="end"
                    loadingIndicator=".ok."
                    variant="contained"
                >
                    <span>{currentButtonText}</span>
                </LoadingButton> */}

                <br />
                <span id="dot">{currentStep}/{steps}</span>
            </div>
            <div>&nbsp;</div>

            <Outlet />

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Êtes vous sûr de vouloir déverouiller l'indice suivant ?
                        Cela impactera votre score final.
                    </DialogContentText>
                </DialogContent>
                <DialogActions id="buttonBox">
                    <Button id="confirm" onClick={handleSubmit}>Confirmer</Button>
                    <Button id="cancel" onClick={handleClose}>
                        Annuler
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
};

export default Layout;