import Box from '@mui/material/Box';
import "./Chat.css"
import * as React from 'react';
import { useNavigate } from "react-router-dom";

export default function Chat(props: any) {  
  const navigate = useNavigate();
  const [lastMessage, setLastMessage] = React.useState(props.lastMessage);

  React.useEffect(() => {
    // Move the logic inside useEffect
    if (props.isBold) {
      setLastMessage(<b>{props.lastMessage}</b>);
    } 
  }, [props.isBold, props.lastMessage]);

  function LastMessage() {    
    if (lastMessage) {
      return (
        <p id="lastMessage">
          <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 8L4 11L11.5 1M7 11L9 13L17.5 1.5" stroke="black" />
          </svg>
          {lastMessage}
        </p>
      );
    }
    return <p id="lastMessage"><i>Envoyez un message pour commencer la conversation...</i></p>;
  }

  const handleSubmit = (chatId: string) => (event: React.MouseEvent<HTMLButtonElement>) => {
    navigate(`/messages/${chatId}`);
    event.stopPropagation();
  };

  return (
    <Box id="chat" component="span" onClick={handleSubmit(props.id)} >
      <div id="left-panel">
        <img src={props.image} alt={props.name} id="picture" />
        <span id="logged-in">🟢</span>
      </div>
      <div id="right-panel">
        <p id="name">{props.name}</p>
        <LastMessage />
      </div>
    </Box>
  );
}
