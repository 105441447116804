import React, { useState } from 'react';
import NotePreview from './NotePreview';
import NoteFullSize from './NoteFullSize';

const NoteApp = () => {

    const [isFullSize, setIsFullSize] = useState(false);
    const [color, setColor] = useState<string>();
    const [title, setTitle] = useState<string>();
    const [content, setContent] = useState<string>();

    const openNote = (index: number) => {
        setIsFullSize(true)
        setColor(notesData[index].color)
        setTitle(notesData[index].title)
        setContent(notesData[index].content)
    }

    const closeNote = () => {
        setIsFullSize(false)
        setColor("")
        setTitle("")
        setContent("")
    }

    const notesData = [
        { color: "#5A12B8", title: "Liste de courses", content: "- carottes<br />- persil<br />- pommes de terre<br />- céleri<br />- patates douces<br />- avocat" },
        // { color: "#C734F6", title: "Livres à lire", content: "- Le vieil homme et la mer<br />- Arsène lupin, gentleman cambrioleur" },
        { color: "#8E901A", title: "Rdv dentiste", content: "Mardi 15h à Barberaz" },
        { color: "#F27D6B", title: "La planque de Max", content: "Depuis chez Joseph & Xavier, partir tout droit face à la montagne, mais rapidement prendre à droite avant de s'engouffrer au 48. <br /> Une fois sortie du tunnel et après être passé sous l'arche (la petite), prendre à gauche jusqu'à traverser l'artère principale jusqu'au prochain passage. <br />Au bout d'un moment, prendre en face du 124, puis à gauche. Arrivé à la prison, passer sous l'arche végétale et continuer dans un passage assez large pour y laisser passer un éléphant.<br />Une fois arrivé chez André Jacques suivre à droite jusqu'au numéro 11.<br />C'est ici qu'on trouvera comment contacter Max par téléphone. <br /> (On oublie pas qu'un numéro commence toujours par 0 !)" },
        { color: "#4A5E9C", title: "Horaires piscine buisson rond", content: "Lundi : 11h45 - 19h<br />Mardi : 11h45 - 20h<br />Mercredi : 8h - 13h30<br />Jeudi : 11h45 - 19h<br />Vendredi : 11h45 - 19h<br />Samedi : 9h - 13h30<br />Dimanche : Fermé<br />" },
        // { color: "#D81F73", title: "Films à voir", content: "- Un évenement assez confidentiel <br />- Terminator 3 <br />- L'étrange Noël de monsieur Jack" },
        // { color: "#6C30A9", title: "Vacances", content: "Lundi 8h-12h, Mercredi 14h-18h" },
        { color: "#00CC66", title: "Idée sorties dans le coin", content: "- Musée des charmettes<br />- Galerie Eurêka<br />- Paddle sur la lac du Bourget<br />- Sortie ski de fond<br />- Musée Opinel<br />- Abbaye d'Hautecombe<br />- Monastère des freres chartreux <br />- Via ferrata dent du chat <br />- Balade à cheval dans les vignobles" },
        { color: "#A62BC9", title: "To do", content: "Mettre de l'ordre dans mes notes" },
        // { color: "#712D8F", title: "Code promo decathlon", content: "A utiliser avant la fin d'année : <br />BX301LR" },
        // { color: "#E49F57", title: "Anniv Simon", content: "Voir avec Erika et Maxime pour le cadeau et prévoir une bouteille de suze en plus du fromage" }
    ];

    const style = {
        margin: "auto",
        textAlign: "center" as const,
    };

    return (
        <div style={style}>
            <h1 style={{ color: "#FFF", textAlign: "center", marginTop: 0 }}>Mes notes</h1>
            {isFullSize ? <NoteFullSize
                color={color}
                title={title}
                content={content}
                onClick={closeNote}
            /> :
                notesData.map((note, index) => (
                    <NotePreview
                        key={index}
                        index={index}
                        color={note.color}
                        title={note.title}
                        content={note.content}
                        onClick={openNote}
                    />
                ))}
        </div>
    );
};

export default NoteApp;
