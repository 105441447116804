import Box from '@mui/material/Box';
import MyAppBar from '../components/AppBar';
import MessagesGrid from '../components/MessagesGrid';
import { getCookie, firebaseConfig } from '../urbanlock';
import { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { getDatabase, ref, onValue } from 'firebase/database';
import EndGame from './EndGame';


function Messages() {

    const [gameOver, setGameOver] = useState(false);
    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);
    const gameCode = getCookie('game-code');

    useEffect(() => {
        const globalRef = ref(db, `${gameCode}/global`);

        const hintsListener = onValue(globalRef, (snapshot) => {
            const data = snapshot.val();
            if (data?.game_over) {
                setGameOver(true);
            }
        });

        // Clean up listener when component unmounts
        return () => {
            hintsListener();
        };
    }, [db, gameCode]);


    return (
        <Box>
            {gameOver ? <EndGame /> : null}
            <MyAppBar title="Messages" />
            <div style={{ marginTop: '80px' }}></div>
            <MessagesGrid />
        </Box>
    );
}

export default Messages;