import * as React from 'react';
import Box from '@mui/material/Box';
import "./HintCard.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from "../urbanlock"
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/ArrowForwardIos';
import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';

const gameCode = getCookie("game-code")

export default function HintCard(props: any) {
  const [open, setOpen] = React.useState(false);
  const [openBackDrop, setOpenBackDrop] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleClickOpen = () => {
    if (props.id === 1000) {
      navigate(`/support`)
    } else {
      if (props.locked) {
        if (getCookie('warn_user_hint_are_impacting_final_score')) {
          handleSubmit(props.id);
        } else {
          setOpen(true);
          setCookie('warn_user_hint_are_impacting_final_score', true, 1);
        }
      } else {
        navigate(`/indices/${props.id}`);
      }
    }
  };

  const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleSubmit = (hint: string, event?: React.MouseEvent<HTMLButtonElement>) => {
    setLoading(true)
    setOpenBackDrop(true)
    if (event) {
      event.stopPropagation();
    }
    fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_UNLOCK_HINT}?game=${gameCode}&hint=${hint}&step=0`).then(data => {
      setLoading(false)
      setOpenBackDrop(false)
      setOpen(false);
      navigate(`/indices/${hint}`);
    }
    );
  };


  return (
    <Box id="hintCard" onClick={handleClickOpen}>
      <div>
        <div id="lock">
          {props.locked ? <span>🔒</span> : null}
        </div>

        <img src={props.image} alt={props.title} />

        <p>{props.title}</p>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {props.title.toUpperCase()}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Êtes vous sûr de vouloir déverouiller cet indice ?
            Cela impactera votre score final.
          </DialogContentText>
        </DialogContent>
        <DialogActions id="buttonBox">
          {/* <Button id="confirm" onClick={(event) => handleSubmit(props.id, event)}>Confirmer</Button> */}



          <LoadingButton
            id="confirm"
            color="secondary"
            loading={loading}
            onClick={(event) => handleSubmit(props.id, event)}
            loadingPosition="start"
            startIcon={<SendIcon />}
          >
            <span>Confirmer</span>
          </LoadingButton>

          <Button id="cancel" onClick={handleClose}>
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openBackDrop}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
}
