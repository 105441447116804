import Box from '@mui/material/Box';
import { useEffect, useState } from 'react';
import MyAppBar from '../components/AppBar';
import Grid from '@mui/material/Grid';
import HintCard from '../components/HintCard';
import EndGame from './EndGame';
import Support from '../assets/img/hints/support/troubleshooting.png'


import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getCookie, firebaseConfig } from "../urbanlock"

const gameCode = getCookie("game-code")


const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const hintsRef = ref(db, `${gameCode}/hints`);

interface Hint {
  id: string;
  title: string;
  image: string;
  locked: boolean;
}


function Hints() {

  const [gameOver, setGameOver] = useState(false);
  const app = initializeApp(firebaseConfig);
  const db = getDatabase(app);
  const gameCode = getCookie('game-code');

  useEffect(() => {
    const globalRef = ref(db, `${gameCode}/global`);

    const hintsListener = onValue(globalRef, (snapshot) => {
      const data = snapshot.val();
      if (data?.game_over) {
        setGameOver(true);
      }
    });

    // Clean up listener when component unmounts
    return () => {
      hintsListener();
    };
  }, [db, gameCode]);
  const [hintsList, setHintsList] = useState<any[]>([]);

  useEffect(() => {
    onValue(hintsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const hintsList: Hint[] = Object.keys(data).map((hintId) => ({
          id: hintId,
          title: data[hintId].title,
          image: data[hintId].image,
          locked: data[hintId].locked
        }));
        setHintsList(hintsList);
      }
    });
  }, []);
  return (
    <Box>
      {gameOver ? <EndGame /> : null}

      <MyAppBar title="Besoin d'aide ?" />
      <div style={{ marginTop: '80px' }}></div>
      <Grid container spacing={0} sx={{ marginBottom: '60px' }}>
        {hintsList.map((hint) => (
          <Grid item xs={6} sx={{ padding: '10px' }} key={hint.id}>
            <HintCard locked={hint.locked} title={hint.title} image={hint.image} id={hint.id} />
          </Grid>
        ))}
         <Grid item xs={6} sx={{ padding: '10px' }} key={1000}>
           <HintCard locked={false} title="Rapporter un bug" image={Support} id={1000} />
          </Grid>
      </Grid>
      <br />
    </Box>
  );
}

export default Hints;