import React, { useState } from 'react';
import style from './UnlockedMessages.module.css'
import erika from '../assets/img/tools/phone/callLog/erika.jpg'
import simon from '../assets/img/tools/phone/callLog/simon.jpg'
import maxime from '../assets/img/tools/phone/callLog/maxime.jpg'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

import notifSound from '../assets/sounds/message-notification.mp3';

import { getCookie, setCookie } from "../urbanlock"


export default function UnlockedMessages() {
    const gameCode = getCookie("game-code")

    const [newMessageSnackOpen, setNewMessageSnackOpen] = React.useState(false);

    fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_PUSH_NEW_MESSAGE_IN_CONVO}?game=${gameCode}&message=maitre-riboule-asking-for-news`).then(data => {
        if (data.status === 200) {
            setNewMessageSnackOpen(true)
            setCookie("new_message", "maitre-riboule", 1)
            const audio = new Audio(notifSound);
            audio.loop = false;
            audio.play();
        }
    })
    const [conversation, setConversation] = useState("");
    const openConversation = (name: string) => {
        setConversation(name)
    }

    const Line = ({ name, date, lastMessage, picture }: { name: string; date: string; lastMessage: string; picture: string }) => {
        return (
            <div className={style.logLine} onClick={() => openConversation(name)}>
                <img alt="profilePicture" className={style.picture} src={picture} />
                <div className={style.left}>
                    <p className={style.name}>{name}</p>
                    <p className={style.lastMessage}>{lastMessage}</p>
                </div>
                <div className={style.right}>
                    <span className={style.date}>{date}</span>
                </div>
            </div>
        )
    }


    const dateOfDaysAgo = (daysAgo: number) => {
        const months = [
            'Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin',
            'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'
        ];

        const today = new Date();
        today.setDate(today.getDate() - daysAgo);

        const day = today.getDate();
        const monthIndex = today.getMonth();

        const formattedDate = `${day < 10 ? '0' : ''}${day} ${months[monthIndex].substring(0, 3)}.`;
        return formattedDate
    }

    const contacts: { [key: string]: any } = {
        Erika: {
            date: `${dateOfDaysAgo(2)}`,
            lastMessage: "Je ne me ferais pas prendre - ne t'inquiète pas. On se voit ce soir <3",
            picture: erika,
            conversation: [
                { you: "J'ai hâte de te retrouver ce soir. Simon garde les enfants à la maison donc on aura toute la soirée pour nous <3" },
                { me: "Moi aussi j'ai hâte de te retrouver" },
                { me: "Par contre on ne pourra pas continuer comme ça éternelement, on va devoir lui dire pour nous un jour..." },
                { you: "Tu sais que ma situation est compliqué..." },
                { me: "Je sais... et il faudra aussi qu'on rediscute de cette histoire de médaillon..." },
                { you: "Tu n'arriveras pas à m'en empêcher ! Je te l'ai déjà dis ce médaillon est un héritage de ma famille." },
                { you: "Je dois le récupérer ! Que tu m'aide ou non !" },
                { me: "On en reparlera ce soir" },
                { you: "Non. Je veux passer un bon moment et tu sais que c'est un sujet qui nous fache" },
                { you: "Ma décision est prise : dans les jours qui viennent je vais reprendre à la ville ce bijou qu'ils m'ont volé." },
                { you: "L'orbre des Alpes a été dans ma famille pendant plus de 10 générations, et il doit y revenir." },
                { me: "Et si tu te fais prendre ? Je ne peux pas t'imaginer en prison, ça me brise le coeur..." },
                { you: "Je ne me ferais pas prendre - ne t'inquiète pas. On se voit ce soir <3" },
            ]
        },
        Max: {
            date: `${dateOfDaysAgo(2)}`,
            lastMessage: "yes carrément je te tiens au courrant",
            picture: maxime,
            conversation: [
                { you: "Salut gros ! Souviens toi que si tu as besoin d'aide pour quoi que ce soit, tu peux venir à la maison ;)" },
                { me: "Je sais bien que je peux compter sur toi - merci Max :)" },
                { you: "Pas de soucis, on se capte un de ces jours !" },
                { me: "yes carrément je te tiens au courrant" }
            ]
        },
        Simon: {
            date: `${dateOfDaysAgo(2)}`,
            lastMessage: "Arf tant pis, passe une bonne soirée alors",
            picture: simon,
            conversation: [
                { you: "Salut Bastien ! Erika sors en ville avec des amies à elle ce soir et je suis bloqué à la maison avec les enfants." },
                { you: "Ca te branche de venir manger à la maison ?" },
                { me: "Hello ! Ah ça aurait été avec plaisir mais je suis pas dispo ce soir, désolé !" },
                { you: "Arf tant pis, passe une bonne soirée alors" },
            ]
        },

    }


    const MessagesList = () => {
        const contactArray = Object.entries(contacts).map(([name, contact]) => ({
            name,
            date: contact.date,
            lastMessage: contact.lastMessage,
            picture: contact.picture
        }));

        return (
            <>
                <h1 style={{ color: "#FFF", textAlign: "center", marginTop: 0 }}>Messages privés</h1>
                <div className={style.mainContainer}>
                    {contactArray.map((contact, index) => (
                        <Line
                            key={index}
                            name={contact.name}
                            date={contact.date}
                            lastMessage={contact.lastMessage}
                            picture={contact.picture}
                        />
                    ))}
                </div>
            </>
        )
    }

    const Msg = (props: any) => {
        const sentByMe = {
            width: "60%",
            marginRight: "10px",
            marginLeft: "auto",
            marginBottom: "20px",
            backgroundColor: "#5F9EA0",
            color: "#fff",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomLeftRadius: "10px",
            padding: "10px",
        }
        const sentByYou = {
            marginBottom: "20px",
            marginLeft: "10px",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            borderBottomRightRadius: "10px",
            width: "60%",
            backgroundColor: "#fff",
            color: "#000",
            padding: "10px",
        }
        let css: any = {}
        props.position === "me" ? css = sentByMe : css = sentByYou;
        return (
            <div style={css}>
                {props.content}
            </div>
        );
    }

    const MessageDetail = (props: any) => {
        const name = props.who;
        const conversation = contacts[name].conversation;
        return (
            <>
                <h2 style={{ paddingBottom: 10, marginTop: -1, backgroundColor: "#333333", position: 'fixed', textAlign: "center", width: "100%", display: "inline-block", color: "#FFF" }}>{name}</h2>
                <svg style={{ position: 'fixed', left: 15, display: "inline-block" }} onClick={() => setConversation("")} fill="#ffffff" height="20px" width="20px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 330 330" stroke="#ffffff">
                    <g id="SVGRepo_bgCarrier" stroke-width="0" />
                    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />
                    <g id="SVGRepo_iconCarrier">
                        <path id="XMLID_6_" d="M165,0C74.019,0,0,74.019,0,165s74.019,165,165,165s165-74.019,165-165S255.981,0,165,0z M205.606,234.394 c5.858,5.857,5.858,15.355,0,21.213C202.678,258.535,198.839,260,195,260s-7.678-1.464-10.606-4.394l-80-79.998 c-2.813-2.813-4.394-6.628-4.394-10.606c0-3.978,1.58-7.794,4.394-10.607l80-80.002c5.857-5.858,15.355-5.858,21.213,0 c5.858,5.857,5.858,15.355,0,21.213l-69.393,69.396L205.606,234.394z" />
                    </g>
                </svg>
                <div style={{ marginTop: "45px" }}>
                    {conversation.map((message: any, index: any) => (
                        <Msg
                            key={index}
                            position={Object.keys(message)[0]}
                            content={message[Object.keys(message)[0]]}
                        />
                    ))}
                </div>
            </>
        )
    }


    return (
        <>
            {conversation === "" ? <MessagesList /> : <MessageDetail who={conversation} />}
            <Snackbar open={newMessageSnackOpen} autoHideDuration={10000} onClose={() => setNewMessageSnackOpen(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                <Alert severity="info" sx={{ width: '100%' }}>
                    Vous avez reçu un nouveau message de Maitre Riboule
                </Alert>
            </Snackbar>
        </>
    );
}


