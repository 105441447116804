export const botLatencyMs = 4000;

async function getData(url = "") {
    const response = await fetch(url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            "Content-Type": "application/json",

        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
    });
    return response.json();
}


export function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
}


export const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);


export function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function deleteCookie(name, path, domain) {
    if (getCookie(name)) {
        document.cookie = name + "=" +
            ((path) ? ";path=" + path : "") +
            ((domain) ? ";domain=" + domain : "") +
            ";expires=Thu, 01 Jan 1970 00:00:01 GMT";
    }
}

export class Game {
    constructor(code) {
        this.code = code.toUpperCase();
        this.startNewUrl = `${process.env.REACT_APP_AZURE_FUNCTION_URI_START_GAME}/${this.code}?code=${process.env.REACT_APP_AZURE_FUNCTION_CODE}`;
    }

    async startNew() {
        try {
            const data = await getData(this.startNewUrl);
            if (data.error) {
                deleteCookie("game-code")
                throw new Error(data.error);
            } else {
                this.data = data;
                // console.log("game has been initialised");
                setCookie("game-code", this.code, 1);
            }
        } catch (error) {
            console.error(error);
        }
    }
}
