import Grid from '@mui/material/Grid';
import ToolCard from './ToolCard';
import { useEffect, useState } from 'react';
// import Smartphone from '../assets/img/smartphone.jpg'
// import Videosurveillance from '../assets/img/videosurveillance.jpg'

import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
import { getCookie,firebaseConfig} from "../urbanlock"

const gameCode = getCookie("game-code")


const app = initializeApp(firebaseConfig);

const db = getDatabase(app);
const toolsRef = ref(db, `${gameCode}/tools/`);

interface Tool {
  id: string;
  title: string;
  image: string;
  description: string;
}

// function Hints() {
//   const [hintsList, setHintsList] = useState<any[]>([]);

//   useEffect(() => {
//     onValue(hintsRef, (snapshot) => {
//       const data = snapshot.val();
//       if (data) {
//         const hintsList: Hint[] = Object.keys(data).map((hintId) => ({
//           id: hintId,
//           title: data[hintId].title,
//           image: data[hintId].image,
//           locked: data[hintId].locked
//         }));
//         setHintsList(hintsList);
//       }
//     });
//   }, []);
//   return (
//     <Box>
//       <MyAppBar title="Indices" />
//       <div style={{ marginTop: '80px' }}></div>
//       <Grid container spacing={0} sx={{ marginBottom: '60px' }}>
//         {hintsList.map((hint) => (
//           <Grid item xs={6} sx={{ padding: '10px' }} key={hint.id}>
//             <HintCard locked={hint.locked} title={hint.title} image={hint.image} id={hint.id} />
//           </Grid>
//         ))}
//       </Grid>
//       <br />
//     </Box>
//   );
// }


// const data = {
//   "telephone": {
//     title: "Smartphone",
//     enabled: false,
//     image: Smartphone,
//     description: "Le téléphone de Mr. Landro"
//   },
//   "cctv": {
//     title: "Vidéosurveillance",
//     enabled: false,
//     image: Videosurveillance,
//     description: "Réseau de caméra de videosurveillance de la ville"
//   }
// }

function SingleColumnGrid() {
  const [toolsList, setToolsList] = useState<any[]>([]);

  useEffect(() => {
    onValue(toolsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const toolsList: Tool[] = Object.keys(data).map((toolId) => ({
          id: toolId,
          title: data[toolId].title,
          image: data[toolId].image,
          description: data[toolId].description
        }));
        setToolsList(toolsList);
      }
    });
  }, []);
  return (
    // <Grid container sx={{ marginBottom: '50px' }}>
    //   {Object.entries(data).map(([id,tool]) => (
    //     <Grid item xs={12} sx={{ padding: '20px' }} key={id}>
    //       <ToolCard enabled={tool.enabled} title={tool.title} image={tool.image} description={tool.description} />
    //     </Grid>
    //   ))}
    // </Grid>
    <Grid container sx={{ marginBottom: '50px' }}>
         {toolsList.map((tool) => (
           <Grid item xs={12} sx={{ padding: '20px' }} key={tool.id}>
             <ToolCard description={tool.description} title={tool.title} image={tool.image} id={tool.id} />
           </Grid>
         ))}
    </Grid>
  );
}

export default SingleColumnGrid;