const NotePreview = (props: any) => {
    const { onClick } = props;

    const card = {
        color: "#FFF",
        backgroundColor: props.color,
        margin: "10px",
        width: "90%",
        height: "100%",
        minHeight:"250px",
        maxHeight: "400px",
        display: "inline-block",
        boxShadow: "2px 2px 5px black",
        overflow: "scroll",
    };
    const title = {
        display: "block",
        marginTop: "10px",
        fontSize: "14px",
        fontWeight: "bold",
    };
    const content = {
        padding: "5px",
        marginLeft: "5px",
        textAlign: "left" as const

    };
    const close = {
        textAlign:"right" as const,
        float:"right" as const,
        marginTop:"5px",
        marginRight:"10px",
        fontWeight:"bold"

    };

    const handleClick = () => {
        onClick(props.index); 
    };
    return (
        <div style={card} onClick={handleClick}>
            <span style={close}>X</span>
            <span style={title}>{props.title}</span>
            <p style={content} dangerouslySetInnerHTML={{__html: props.content}}></p>
        </div>
    )
};

export default NotePreview;