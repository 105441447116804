import { useNavigate, Outlet, useParams } from "react-router-dom";
import './MessagesDetails.css'
// import React, { useEffect, useState, CSSProperties, useRef } from 'react';
import React, { useEffect, useState, CSSProperties } from 'react';

import { initializeApp } from "firebase/app";
import { getDatabase, ref, onValue } from "firebase/database";
// import { getCookie, firebaseConfig, botLatencyMs } from "../urbanlock"
import { getCookie, firebaseConfig, deleteCookie, setCookie } from "../urbanlock"
import LeftArrow from '../assets/img/leftArrow.svg'

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";


const gameCode = getCookie("game-code")

const Layout = () => {

    const [newToolUnlockedSmartphone, setNewToolUnlockedSmartphone] = React.useState(false);
    const [newToolUnlockedCCTV, setNewToolUnlockedCCTV] = React.useState(false);


    const app = initializeApp(firebaseConfig);
    const db = getDatabase(app);

    const params = useParams();

    const [name, setName] = useState("");
    const [image, setImage] = useState("");
    const [botId] = useState(params.messages);
    const [answerPropositions, setAnswerPropositions] = useState<any[]>([]);
    const [conversation, setConversation] = useState<any[]>([]);
    const [position] = useState<CSSProperties>({ position: "sticky" });
    const [isTyping, setIsTyping] = useState<CSSProperties>({ display: "none" });
    const [open, setOpen] = React.useState(false);

    if (getCookie("new_message")) {
        deleteCookie("new_message")
    }

    const chatsRef = ref(db, `${gameCode}/chats/${params.messages}`);
    useEffect(() => {
        const hintsListener = onValue(chatsRef, (snapshot) => {
            const data = snapshot.val();

            // Lines below allow to remove last bot messages from data.conversation list
            // so they are not rendered 
            //
            // var tempoMsg = []
            // for (var i = data.conversation.length - 1; i >= 0; i--) {
            //     if (data.conversation[i].from == "bot") {
            //         tempoMsg.push(data.conversation[i].content)
            //         data.conversation.pop(i)
            //     } else {
            //         break
            //     }
            // }

            setName(data.name)
            setImage(data.image)
            if (data.answer_propositions) {
                setAnswerPropositions(data.answer_propositions)
            } else {
                setAnswerPropositions([])
            }
            setIsTyping({ display: "none" })
            setConversation(data.conversation)

            // Lines below allow to inject some latency before doing something
            //
            // function sleep(ms: number) {
            //     return new Promise(resolve => setTimeout(resolve, ms));
            // }
            // async function injectLatency(ms: number) {
            //     await sleep(ms)
            //     // do something with some latency
            // }
            // injectLatency(botLatencyMs)
        }
        );
        return () => {
            hintsListener();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const scrollingElement = (document.scrollingElement || document.body);
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
    })


    const handleSubmitAnswer = (key: string, value: string) => {
        // When the user submit a message, we add its value to the conversation
        // so there is not latency - this improve the app feel

        if (key === "end-game") {
            setOpen(true)
            setAnswerPropositions([])
            fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_FINISH_GAME}?game=${gameCode}`).then(() => {
                setOpen(false)
                navigate(-1)
            }
            );
        }
        else {
            // unlock "téléphone de bastien"
            if (key === "5c7b50f04661ed4d") {
                setNewToolUnlockedSmartphone(true)

            }
            if (key === "trouvons-des-preuves" || key === "pas-ultra-chaud") {
                setNewToolUnlockedCCTV(true)
                setCookie("new_tool", "maitre-riboule", 1)

            }
            setAnswerPropositions([])
            const localMessage = {
                "content": value,
                "from": "player"
            }
            const localConversation = [...conversation, localMessage];
            setConversation(localConversation);
            // Then we send its input to the server, and the whole convo gets rewritten for everyone

            fetch(`${process.env.REACT_APP_FIREBASE_FUNCTION_URI_SEND_MESSAGE}?game=${gameCode}&message_id=${key}&bot_id=${botId}`)
                .catch(console.error)
            setIsTyping({ display: "block" })
        }
    }

    let navigate = useNavigate();
    if (conversation.length === 0) {
        return (
            <>
                <div id="headerMessagesDetails">
                    <img alt="" onClick={() => navigate(-1)} id="leftArrow" src={LeftArrow} />
                    <img alt="" id="pp" src={image} />
                    <p id="title">{name}</p>
                </div>
                <div id="Messagescontainer">
                    <div id="contentMessagesDetails">
                        <div style={{ marginTop: "50%", textAlign: "center" }}>
                            Cette conversation est vide.<br /> Envoyez un message pour commencer la discussion !
                        </div>
                    </div>



                    <div id="answersPropositions" style={position}>
                        {Object.entries(answerPropositions).map(([key, value]) => {
                            return (
                                <div className={`answerPropal`} key={key} onClick={() => handleSubmitAnswer(key, value)}>
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Outlet />
            </>
        )
    } else {
        return (
            <>
                <div id="headerMessagesDetails">
                    <img alt="" onClick={() => navigate(-1)} id="leftArrow" src={LeftArrow} />
                    <img alt="" id="pp" src={image} />
                    <p id="title">{name}</p>
                </div>
                <div id="Messagescontainer">
                    <div id="contentMessagesDetails">
                        {Object.entries(conversation).map(([key, value]) => {
                            return (
                                <div key={key} className={`${value.from}`}>
                                    <p>{value.content}</p>
                                </div>
                            );
                        })}
                        <div id="isTyping" className="bot" style={isTyping} >
                            <svg height="40" width="40" className="loader">
                                <circle className="dot" cx="10" cy="20" r="3" />
                                <circle className="dot" cx="20" cy="20" r="3" />
                                <circle className="dot" cx="30" cy="20" r="3" />
                            </svg>
                        </div>
                    </div>



                    <div id="answersPropositions" style={position}>
                        {Object.entries(answerPropositions).map(([key, value]) => {
                            return (
                                <div className={`answerPropal`} key={key} onClick={() => handleSubmitAnswer(key, value)}>
                                    {value}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <Snackbar open={newToolUnlockedSmartphone} autoHideDuration={5000} onClose={() => setNewToolUnlockedSmartphone(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        Vous avez débloquez un nouvel outil : <br />Téléphone de Bastien
                    </Alert>
                </Snackbar>

                <Snackbar open={newToolUnlockedCCTV} autoHideDuration={5000} onClose={() => setNewToolUnlockedCCTV(false)} anchorOrigin={{ vertical: "top", horizontal: "center" }}>
                    <Alert severity="info" sx={{ width: '100%' }}>
                        Vous avez débloquez un nouvel outil : <br />Réseau de Vidéosurveillance
                    </Alert>
                </Snackbar>

                <Outlet />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={open}
                // onClick={handleClose}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </>
        )
    }
};

export default Layout;